import $ from "jquery";

// Looks in data-tooltip attributes and replaces things
// like UTC(1426269748) and LOCAL(1426269748) with a nice
// string like 2015-03-13 18:02:08 UTC or March 13, 2015 3:02pm EDT
(function () {
  // Returns "March 13, 2015 3:02pm EDT"
  const format_local = function(d: Date): string {
    return d.toLocaleTimeString([], {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: undefined,
      timeZoneName: "short",
    })
  };

  // Returns "2015-03-13 18:02:08 UTC"
  const format_utc = function(d: Date): string {
    // Left pad numbers with a 0 so they are always at least 2 characters long.
    //
    // This is a minimal version of String.padStart which should work across
    // all browsers.
    const padded = function(n: number): string {
      if(n < 10) {
        return "0" + String(n)
      } else {
        return String(n)
      }
    }

    const date = `${d.getUTCFullYear()}-${padded(d.getUTCMonth() + 1)}-${padded(d.getUTCDate())}`
    const time = `${padded(d.getUTCHours())}:${padded(d.getUTCMinutes())}:${padded(d.getUTCSeconds())}`

    return `${date} ${time} UTC`
  }

  $("[data-tooltip]").each(function (_, el) {
    const tooltip = el.getAttribute("data-tooltip");

    const replaced = tooltip
      .replace(/LOCAL\((\d+)\)/g, function (_: string, seconds: string) {
        return format_local(new Date(parseInt(seconds, 10) * 1000));
      })
      .replace(/UTC\((\d+)\)/g, function (_: string, seconds: string) {
        return format_utc(new Date(parseInt(seconds, 10) * 1000))
      });

    el.setAttribute("data-tooltip", replaced);
  });
}());
