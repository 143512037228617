// User avatar upload handling. This updates the preview of the avatar once they
// have selected an image to upload.
const avatar = document.querySelector("input#user_avatar") as HTMLInputElement;

if(avatar) {
  avatar.addEventListener("change", function() {
    const reader = new FileReader();

    // Once the image 
    reader.onload = function(e: ProgressEvent) {
      if(e.type != "load") {
        return
      }

      const img: HTMLImageElement = document.querySelector("#edit_user img.avatar");
      if(img) {
        img.src = reader.result.toString();
      }
    };

    // Read the file from disk as base64 encoded data URL
    reader.readAsDataURL(avatar.files[0]);
  });
}
