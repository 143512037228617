import $ from "jquery";

/* global Stripe */
(function() {
  $(document).on("reset", ".update-billing > form", function() {
    $(".update-billing").addClass("hidden");
    $(".billing-summary").removeClass("hidden");
    $(".current-cc").removeClass("hidden");
  });

  $(document).on("click", "a.more-invoices", function() {
    $(".invoices--list tr").show();
    $(this).hide();

    return false;
  });

  // Allow a query parameter override (p=false) for requiring the postal
  // code. A Canadian customer with a US card with billing in Canada can't
  // update their card.
  let hidePostal = false;
  if(typeof URLSearchParams !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    hidePostal = query.get("p") == "false";
  }

  if($("#card-element").length) {
    const stripe = Stripe($("meta[name='stripe-key']").attr("content"));
    const elements = stripe.elements();

    const card = elements.create("card", {
      hidePostalCode: hidePostal,
      style: {
        invalid: {
          // $state-danger
          color: "#b94a48",
        },
        base: {
          "::placeholder": {
            // $muted-text-color
            color: "#999",
          },
        }
      }
    });
    card.mount("#card-element");

    // Focus the credit card field when it's been loaded.
    card.addEventListener("ready", card.focus);

    // Show validation errors
    const errors = $("#card-errors");
    card.addEventListener("change", function(event) {
      if(event.error) {
        errors.text(event.error.message);
      } else {
        // Using space to keep the save button from jumping.
        errors.html("&nbsp;");
      }
    });

    // Ensure the card is valid before submitting the form.
    const form = $("#card-element").parents("form");
    form.on("submit", function(e) {
      e.preventDefault();
      e.stopPropagation();

      const button = form.find("input:submit");
      button.prop("disabled", true);

      // Skip card form validation if the Stripe token is not required and
      // credit card field is empty. This happens on the edit plan form that
      // has entries for billing email and address and the card number is
      // optional.
      const token = form.find("input[name=stripe_card_token]");
      if(!token.prop("required") && $("#card-element.StripeElement--empty").length !== 0) {
        form.get(0).submit();
        return;
      }

      stripe.createToken(card).then(function(result) {
        if(result.error) {
          $("#card-errors").text(result.error.message);
          button.prop("disabled", false);
        } else {
          token.val(result.token.id);
          form.get(0).submit();
        }
      });
    });
  }
})();
