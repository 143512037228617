import $ from "jquery";

$(function() {
  // The radio buttons on the page have a data-url attribute which provides the
  // actual link we want to go to.
  //
  // This is a temporary (2018-07-04) shim until we're able to redesign the
  // change plan page.
  $(".plans.edit .button.confirm-plan-change").on("click", function() {
    const url = $("input[name=slug]:checked").first().data("url");

    // Avoid refreshing the page if one of the radio buttons isn't checked.
    if(url != undefined) {
      window.location = url;
    }

    return false;
  });
});
