// Clear the Intercom cookie when the user clicks on Sign Out
const signout = document.getElementById("sign_out");

if(signout) {
  signout.addEventListener("click", function() {
    if(typeof(Intercom) === "function") {
      Intercom("shutdown");
    }
  });
}
