// Code highlighting
import hljs from "highlight.js/lib/core";

import http from "highlight.js/lib/languages/http";
hljs.registerLanguage("http", http);

import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);

import ruby from "highlight.js/lib/languages/ruby";
hljs.registerLanguage("ruby", ruby);

import python from "highlight.js/lib/languages/python";
hljs.registerLanguage("python", python);

// github-markdown puts the language in a lang attribute on the <pre> element
// but highlight.js wants it in the class of the <code> element
(function() {
  // blocks is the set of code blocks that we need to enable syntax
  // highlighting on.
  const blocks = document.querySelectorAll("[data-highlight]");

  for(const elem of blocks) {
    let parent = elem.parentElement;

    // Ensure each code block is wrapped in a `pre` tag
    // TODO: Figure out what why do this
    if(!parent.tagName != "PRE") {
      const pre = document.createElement("pre");

      parent.insertBefore(pre, elem);
      pre.appendChild(elem);

      parent = pre;
    }
    parent.classList.add("hljs-wrapper");

    let langClass = elem.dataset.highlightLang;
    if(!langClass) {
      langClass = "nohighlight";
    }

    elem.classList.add("hljs");
    elem.classList.add(langClass);

    hljs.highlightElement(elem);
  }
})();
