import $ from "jquery";

(function() {
  // Click handler for the Snitch list
  document.querySelector(".snitch-list")?.addEventListener("click", (event: MouseEvent) => {
    const target = event.target as HTMLElement

    // Don't override the default click handler when clicking within the
    // controls section.
    if(target.closest(".snitch-controls")) {
      // Unpause button handler
      const unpause = target.closest<HTMLElement>(".snitch-controls a.unpause[href]")
      if(unpause) {
        event.preventDefault()

        // TODO: Handle errors
        fetch(unpause.getAttribute("href")).then(
          (resp: Response) => {
            if(resp.ok) {
              target.closest(".snitch.paused")?.classList.remove("paused")
            }
          },
        )
      }

      return
    }

    // Use the default click handler for any links. This allows the browser to
    // support meta keys and the like.
    if(target.matches("a")) {
      return
    }

    // Clicking on the icon, name, interval, or last check-in should take the
    // user to the Snitch's history page which is linked via the name.
    const href = target.closest(".snitch")?.querySelector<HTMLElement>(".name[href]")?.getAttribute("href")
    if(href) {
      event.preventDefault()
      window.location.href = href
    }
  })

  $(".tags-column .tag").each((i, e) => $(e).css({"padding-right": $(e).find(".tag-counts").width() + 5}).attr("data-counts", true));

  // Toggle the visiblity of the list of tags on mobile.
  document.querySelector("#tags-toggle")
    ?.addEventListener("click", () => document.querySelector(".snitches-index")?.classList.toggle("tags-shown"));

  // Snitches#Setup
  //
  // Set up instruction switcher.
  const setupNav = document.querySelector(".snitches-sidebar .setup-nav");
  if(setupNav) {
    const view = document.querySelector(".setup-details .setup-content");

    // Clicking on one of the set up types (e.g HTTP, Field Agent, etc...) will
    // switch the instructions view to the correct instructions.
    setupNav.addEventListener("click", (e) => {
      const target = (e.target as HTMLElement).closest("a");

      e.preventDefault();

      // Toggle highlighting of the set up nav item to signify which one the
      // customer is currently looking at.
      setupNav.querySelectorAll("a").
        forEach((el) => el.classList.toggle("active", el == target));

      // The navigation list uses anchors with an href (e.g. #install-http) that
      // is an ID selector which will match the set up instructions we need to
      // show. This will also hide any current active instructions to only show
      // the one set.
      view.querySelectorAll(".setup-content > div").
        forEach((el) => el.classList.toggle("hidden", !el.matches(target.hash)));
    });
  }
})();

class SnitchForm {
  private current: string;
  private form: HTMLFormElement;

  constructor(form: HTMLFormElement) {
    if(!form) {
      return
    }

    this.form = form;
    this.current = (form.querySelector("input[name='monitor[type]']:checked") as HTMLInputElement).value;
    this.showOptions(this.current);

    // Changing the type shows the correct fieldset
    form.querySelector(".snitch-form__type")
      ?.addEventListener("change", (e: InputEvent) => { this.typeChanged(e) });

    // Hide / Show Basic interval description
    form.querySelector(".snitch-form__type-options[name='monitor[basic]']")
      ?.addEventListener("change", (e: InputEvent) => { this.basicIntervalChanged(e) });

    // Sync disabled state of the submit button for the Smart form
    form.querySelector(".snitch-form__type-options[name='monitor[smart]']")
      ?.addEventListener("change", (e: InputEvent) => { this.smartIntervalChanged(e) });
  }

  showOptions(type: string): void {
    this.form.querySelectorAll("fieldset.snitch-form__type-options")
      .forEach((el: HTMLElement) => el.classList.toggle("hidden", el.dataset.type != type));

    if(type == "basic") {
      this.showBasicIntervalHint();
    }

    this.current = type;
  }

  // Show the correct interval hint for Basic snitches based on which interval is checked.
  showBasicIntervalHint(): void {
    const checked = this.form.querySelector("input[name='monitor[basic][interval]']:checked") as HTMLElement;

    this.form.querySelectorAll(".hint.interval[data-interval]")
      .forEach(function(el: HTMLElement) {
        el.classList.toggle("hidden", el.dataset.interval != checked?.dataset.interval);
      });
  }

  // Customer changed the type of a Snitch (e.g. Smart to Heartbeat). Try to set
  // an equivalent interval or schedule based on their existing selection.
  typeChanged(event: InputEvent): void {
    const target = event.target as HTMLInputElement

    if(!target.matches("input[name='monitor[type]']")) {
      return
    }

    const from = this.form.querySelector(`fieldset.snitch-form__type-options[data-type="${this.current}"]`);
    const to   = this.form.querySelector(`fieldset.snitch-form__type-options[data-type="${target.value}"]`);
    const interval = from.querySelector("[data-interval]:checked") as HTMLInputElement;

    if(interval) {
      const opt = to.querySelector(`[data-interval="${interval.dataset.interval}"]`) as HTMLInputElement;

      if(opt) {
        if(opt instanceof HTMLOptionElement) {
          opt.selected = true;
        } else {
          opt.checked = true;
        }
      }
    }

    // Disable the submit button when the selected interval isn't supported by the plan or by the scheduler.
    const submit = this.form.querySelector("input[type=submit]") as HTMLInputElement;
    const checked = to.querySelector("input[data-interval]:checked") as HTMLInputElement;
    if(submit) {
      submit.disabled = checked?.disabled;
    }

    // Toggle the visibility of each of the options pages to show the correct options sheet.
    this.showOptions(target.value);
  }

  // Handle changed events for the Basic interval radio buttons to keep the hint in sync.
  basicIntervalChanged(event: InputEvent): void {
    const target = event.target as HTMLInputElement

    if(!target.matches("input[data-interval]")) {
      return
    }

    this.showBasicIntervalHint();
  }

  // Handle change events for the Smart intervals
  smartIntervalChanged(event: InputEvent): void {
    const target = event.target as HTMLInputElement
    if(!target.matches("input[name='monitor[smart][interval]']")) {
      return
    }

    // Disable the submit button when the selected interval isn't supported by the plan or by the scheduler.
    const submit = this.form.querySelector("input[type=submit]") as HTMLInputElement;
    if(submit) {
      submit.disabled = target.disabled
    }
  }
}

new SnitchForm(document.querySelector("form.snitch-settings"));
