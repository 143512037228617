import Clipboard from "clipboard";

document.addEventListener("DOMContentLoaded", function() {
  // Checking for support from the browser
  if(!Clipboard.isSupported()) {
    document.querySelectorAll(".clipboard-btn").
      forEach((el: HTMLElement) => el.style.visibility = "hidden");
  } else {
    new Clipboard(".clipboard-btn");
  }
});
