import $ from "jquery";

const projectForm = {
  pages: ["#plan", "#payment"],
  form: undefined,

  init: function(form) {
    // Don't initialize if the page does not have a new project form
    if (form.length === 0) {
      return;
    }

    this.form = form;

    // Register for hash change events which will be used to moved between each
    // section of the wizard.
    $(window).on("hashchange", () => {
      if (this.pages.indexOf(window.location.hash) >= 0) {
        this.show(window.location.hash);
      }
    });

    // The form will already have a plan checked if the user came from the Plans
    // page, so we show the payment form and avoid asking them a second time.
    if (form.find("input[name='slug']:checked").length === 0) {
      this.show("plan");
    }  else {
      this.show("payment");
    }

    // Links clicked inside of labels need to also check the hidden radio button
    // that selects the plan.
    form.on("click", "label a", function() {
      $(this).siblings("input:radio").prop("checked", true);
    });
  },

  show: function(section: string) {
    // Remove the hash (#) from the section and set the default to "name"
    section = section.replace("#", "");
    if (section === "") {
      section = "plan";
    }

    // Ensure the hash is set to the page we want to show. This will not trigger
    // a hashchange if we are already on that view.
    window.location.hash = section;

    const view = $("[name='" + section + "']");

    // The payment view requires pulling data from the current selection
    if (section === "payment") {
      // Ensure that all of the feature summaries are hidden in case the user
      // clicked back.
      view.find(".hidden-plans").hide();

      // Find the correct summary based on the selected radio button
      const slug = $("input[name='slug']:checked").val();
      const plan = view.find("[data-plan-slug='" + slug + "']");

      // Skip the credit card form validation when choosing the free plan since
      // we don't need a credit card.
      if (slug === "the_lone_snitch") {
        this.form.get(0).submit();
        return;
      }

      // Show the correct summary and fill in the correct price
      plan.parent(".hidden-plans").show();
      view.find(".plan-price-value").text(plan.data("plan-price"));
    }

    // Disable the view and focus the credit card field to keep the user moving
    // along without having to find the input.
    view.show().siblings("[name]").hide();
    view.find(".StripeElement input:text:first").focus();
  },
};

// Make the script work on the plan form
projectForm.init($("form.new_project"));
