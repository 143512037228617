(function() {
  // Return true if the click occurred outside of the Dialog (i.e. on the
  // backdrop). An alternative implementation checks if the event target's
  // nodeName is DIALOG however that doesn't work when the dialog itself has a
  // padding.
  function isClickOutsideDialog(dialog: HTMLDialogElement, event: MouseEvent) {
    const rect = dialog.getBoundingClientRect()

    return (
      event.clientX < rect.left ||
      event.clientX > rect.right ||
      event.clientY < rect.top ||
      event.clientY > rect.bottom
    )
  }

  // When clicking on an element with a `data-modal` attribute we want to
  // trigger that modal to be shown.
  document.querySelectorAll("[data-modal]").forEach((el: HTMLElement) => {
    el.addEventListener("click", (event: MouseEvent) => {
      const dialog = document.querySelector(`dialog#${el.dataset.modal}`) as HTMLDialogElement;

      // Dialog wasn't found so default to the normal click handler
      if(!dialog) {
        return
      }

      // Don't bubble the event
      event.preventDefault()

      // Close any open dialogs before showing the new one.
      document.querySelectorAll("dialog[open]").forEach((e: HTMLDialogElement) => e.close());

      // Finally show the dialog.
      dialog.showModal();
    })
  });

  document.querySelectorAll("dialog").forEach((d: HTMLDialogElement) => {
    d.addEventListener("close", () => {
      // Reset any forms in the dialog
      d.querySelectorAll("form").forEach((f: HTMLFormElement) => f.reset())
    })

    d.addEventListener("click", (ev: MouseEvent) => {
      // Clicking `cancel` in the dialog's form will close the dialog.
      if((ev.target as HTMLElement).matches("[rel='modal:close']")) {
        // Close the dialog and stop processing the event
        d.close()
        ev.preventDefault()

        return
      }
    });

    // Close the dialog when clicking outside of the dialog. We don't use the
    // `click` event as clicking and dragging or selecting an option from a
    // select list could incorrectly trigger the closer. 
    // 
    // Instead we trigger a pointerup check when a pointerdown happens outside
    // of the dialog.
    d.addEventListener("pointerdown", (ev: MouseEvent) => {
      // Event was bubbled up from an element inside the dialog so want to
      // avoid doing anything.
      if(ev.target !== d) {
        return
      }

      // Click was inside the bounds of the dialog so don't do anything
      // special.
      if(!isClickOutsideDialog(d, ev)) {
        return
      }

      // On the next pointerup event we check if the pointer is still outside
      // of the dialog and close it if it is.
      addEventListener("pointerup", (ev: MouseEvent) => {
        if(isClickOutsideDialog(d, ev)) {
          d.close()
        }
      }, { once: true })
    })
  })
})()
