// Entry point for the build script in your package.json

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

import ujs = require("@rails/ujs");
ujs.start();

// ActionCable channels
require("./channels");
require("./public");

// Handle closing of Agent Dude flash messages (with type 'tip_off')
$(".tip-off").on("click", function() {
  $(this).fadeOut(function() {
    $(this).remove();
    $(".topbar-brand-tip-off").removeClass("topbar-brand-tip-off");
  });
});
