import $ from "jquery";

$(function() {
  // Make note of whether or not the user is selecting a paid plan
  const freeUserPlanSelectForm = $(".plan-select-form");

  if (freeUserPlanSelectForm.length) {
    freeUserPlanSelectForm.each(function() {
      const form = $(this);
      const plans = form.find("input[name='plan_id']");

      plans.on("change", function() {
        form.attr("data-paid-plan-selected", $(this).closest(".plan-item").hasClass("paid"));
      });
    });
  }

  $("a.cc-toggle").on("click", function(e) {
    e.preventDefault();

    $("div.update-billing").removeClass("hidden");
    $(".StripeElement input:first").trigger("focus");

    $("div.billing-summary").addClass("hidden");
    $("div.current-cc").addClass("hidden");
  });
});
