import consumer from "./consumer";

// CaseEvent represents the object broadcast for the Case the Kafka event
// handlers.
interface CaseEvent {
  snitch?: {
    token: string
  }
}

// Cases Event Handler
(function() {
  // Only subscribe if we can find a Project ID.
  const projectID = document.querySelector<HTMLElement>("[data-project-id]")?.dataset?.projectId
  if(!projectID) {
    return
  }

  const handler = {
    received: function(event: CaseEvent) {
      // Ignore any event that doesn't include a Snitch token,
      const token = event?.snitch?.token
      if(!token) {
        return
      }

      // Filter out any event that is for a Snitch which is not on the page.
      if(!document.querySelector(`[data-token='${token}']`)) {
        return
      }

      // Reload the page to apply pending updates.
      // TODO: Be more selective and update the page without a reload.
      document.location.reload();
    },
  }

  consumer.subscriptions.create({ channel: "CasesChannel", id: projectID, }, handler)
})();
