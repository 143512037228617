// getQueryParameters extracts the query parameters from the current URL and
// returns them as an object. The URLSearchParams API that is the modern way
// to do this became generally available some time in 2018.
//
// https://caniuse.com/#search=URLSearchParams
export function getQueryParameters(str: string) {
  const params = {};

  (str || document.location.search)
    .replace(/(^\?)/, "")
    .split("&")
    .forEach(function(e: string) {
      const el = e.split("=", 2);
      params[el[0]] = el[1];
    });

  return params;
}
