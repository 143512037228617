import $ from "jquery";

$(function() {
  $(".provider .icon-delete").on("click", function(event) {
    event.preventDefault();

    const href = $(event.target).attr("href");

    $.ajax(href, {
      method: "DELETE",
      complete() {
        window.location.reload();
      }
    });
  });

  // Disable the 'Send Test' button for webhooks when the customer has changed
  // the URL but not saved the change. We currently require the user to save
  // the change before they can test it.
  {
    const url = document.getElementById("integration_webhook_hook_url") as HTMLInputElement;
    if(url) {
      const button = url.closest("form").querySelector("a.button[name='send-test']");

      // The test button is only available on the edit page, so avoid registering
      // the listener when it is not available.
      if(button) {
        url.addEventListener("keyup", () => button.classList.toggle("disabled", url.value != url.defaultValue));
      }
    }
  }

  // Squadcast Test button (same as webhook test above).
  {
    const url = document.getElementById("integration_squadcast_hook_url") as HTMLInputElement;
    if(url) {
      const button = url.parentElement.querySelector("a.button[name='send-test']");

      if(button) {
        url.addEventListener("keyup", () => button.classList.toggle("disabled", url.value != url.defaultValue));
      }
    }
  }

  // Opsgenie test button enabled / disabled check. The button needs to be
  // disabled if either the API key or region change.
  {
    const form = document.querySelector("form.edit_integration_opsgenie") as HTMLFormElement;
    if(form) {
      const button = form.parentElement.querySelector("a.button[name='send-test']");

      const key    = form.querySelector("input[name$='[api_key]'") as HTMLInputElement;
      const region = form.querySelector("select[name$='[region]']") as HTMLSelectElement;

      // changed returns true when at least one of the elements has been
      // modified.
      const changed = function() {
        // Check the API key
        if(key.value != key.defaultValue) {
          return true
        }

        // Check if the region option has changed.
        const options = region.querySelectorAll("option");
        for(const opt of options) {
          if(opt.selected != opt.defaultSelected) {
            return true
          }
        }

        return false
      }

      key.addEventListener("keyup", () => button.classList.toggle("disabled", changed()));
      region.addEventListener("change", () => button.classList.toggle("disabled", changed()));
    }
  }
});
