// Imported from Heroku's Boomerang JS library since it was being blocked by ad
// blockers.
//
// https://github.com/heroku/boomerang
//
// Changed:
//   - Automatic init based on heroku:app and heroku:addon meta tags
//   - Removed local mode
//   - Removed demo / test only code (reset)
//   - Removed toggling
//   - Removed trimmed version when app and addon are not set
class Boomerang {

  constructor(options) {
    if (options == null) {
      options = {};
    }

    // Config
    this.options = options;
    this.cssUrl = "https://s3.amazonaws.com/assets.heroku.com/boomerang/boomerang.css";

    this.app = this.options.app;
    this.addon = this.options.addon;

    // DOM Shortcuts
    this.head = document.querySelector("head");
    this.body = document.querySelector("body");

    this.attachStylesheet();
    this.attachDiv();

    this.body.classList.add("heroku-boomerang-loaded");
  }

  attachStylesheet() {
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel  = "stylesheet";
    link.href = this.cssUrl;

    this.head.appendChild(link);
  }

  attachDiv() {
    this.div = document.createElement("div");
    this.div.className = "boomerang"; // TODO: temporary
    this.div.id = "heroku-boomerang";

    this.div.innerHTML = `\
<a href="#" class="logo">Heroku Add-ons</a>
<ul>
  <li class="big"><a href="http://${this.app}.herokuapp.com">${this.app}</a></li>
  <li class="sub"><a href="https://dashboard.heroku.com/apps/${this.app}/resources">Resources</a></li>
  <li class="sub"><a href="https://dashboard.heroku.com/apps/${this.app}/activity">Activity</a></li>
  <li class="sub"><a href="https://dashboard.heroku.com/apps/${this.app}/access">Access</a></li>
  <li class="sub"><a href="https://dashboard.heroku.com/apps/${this.app}/settings">Settings</a></li>

  <li class="big"><a href="https://addons.heroku.com/${this.addon}">${this.addon}</a></li>
  <li class="sub"><a href="https://devcenter.heroku.com/articles/${this.addon}">Docs</a></li>
</ul>\
`;

    return this.body.appendChild(this.div);
  }

  static init(options) {
    window.boomerang = new Boomerang(options);
  }
}

window.Boomerang = Boomerang;

// Initialize the Heroku nav bar if Heroku meta tags are in place
document.addEventListener("DOMContentLoaded", function() {
  const app   = document.querySelector("meta[name='heroku:app']");
  const addon = document.querySelector("meta[name='heroku:addon']");

  if (app && addon) {
    Boomerang.init({app: app.content, addon: addon.content});
  }
});
