import $ from "jquery";

$(document).on("click", "[data-invitation-modal-add]", function(event) {
  const $add = $(event.currentTarget);
  const $emails = $("[data-invitation-modal-email]");
  const $email = $emails.last();
  const $invitations = $("[data-invitations]");
  if (!$email.length) { return; }
  if ($emails.length >= $invitations.data("invitations") - 1) { $add.hide(); }

  event.preventDefault();

  const $newEmail = $email.clone().val("");
  $newEmail.insertAfter($email);
  $newEmail.focus();
});
