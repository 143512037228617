import $ from "jquery";

// vendored version includes a change to allow it to be imported and update the
// global jQuery object.
import "../vendor/jquery.modal";

// jQuery.modal is assigned by importing vendor/jquery.modal.
const modal = jQuery.modal;

// Update the defaults for the modal.
Object.assign(modal.defaults, {
  modalClass: "modal-wrapper",
  showClose: false,
  showSpinner: false,
  zIndex: 15
});

$(document).on("click", "[rel='modal:close']", function() {
  modal.close();
});

$(document).on("modal:open", ".jquery-modal", function(event, modal) {
  const input = modal.elm[0].querySelector("input[type=text], input[type=password], input[type=email]");
  if(input) {
    input.focus();
  }
});
