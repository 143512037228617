import "./account.ts";
import "./avatar.ts";
import "./billing.ts";
import "./change-plan.ts";
import "./clipboard.ts";
import "./dialogs.ts";
import "./dropdown.ts";
import "./forms.ts";
import "./get-query-parameters.ts";
import "./heroku.ts";
import "./highlighting.ts";
import "./index.ts";
import "./integrations.ts";
import "./intercom.ts";
import "./invitation-modal.ts";
import "./jquery-modal.ts";
import "./langswitch.ts";
import "./new-project.ts";
import "./snitches.ts";
import "./snitch-periods.ts";
import "./subscriptions.ts";
import "./teams.ts";
import "./time_tooltips.ts";
