import $ from "jquery";
import { getQueryParameters } from "./get-query-parameters";

class LangSwitch {
  static initClass() {
    this.sel = {
      code: "[data-langswitch-code]",
      trigger: "[data-langswitch-trigger]"
    };

    this.supportedLangs = ["curl", "ruby", "python", "go"];
  }

  static init() {
    const params = getQueryParameters();
    const initialLang = params.lang != null ? params.lang : "curl";

    if (!$(this.sel.code).length) { return; }

    this.useLang(initialLang);

    $(document).on("click", this.sel.trigger, event => {
      event.preventDefault();
      const $trigger = $(event.currentTarget);
      const lang = $trigger.attr(this.sel.trigger.slice(1).slice(0, -1));
      this.useLang(lang);
    });

    $("html").addClass("js-langswitch-initialized");
  }

  static useLang(lang) {
    if ($.inArray(lang, this.supportedLangs) === -1) { return; }

    this._updateCode(lang);
    this._updateDocument(lang);
    this._updateTriggers(lang);
  }

  static _updateDocument(lang) {
    $("html").removeClass((i, className) => (className.match(/(^|\s)js-langswitch-lang-\S+/g) || []).join(" "));
    $("html").addClass(`js-langswitch-lang-${lang}`);
  }

  static _updateTriggers(lang) {
    const $triggers = $(this.sel.trigger);

    if (!$triggers.length) { return; }

    $triggers.each((i, elem) => {
      const $trigger = $(elem);

      // Get the selector without the attribute brackets
      const sel = this.sel.trigger.slice(1).slice(0, -1);

      let action = "remove";
      if ($trigger.attr(sel) === lang) { action = "add"; }

      $trigger[`${action}Class`]("js-active");
    });
  }

  static _updateCode(lang) {
    const $code = $(this.sel.code);

    if (!$code.length) { return; }

    // Get the selector without the attribute brackets
    const sel = this.sel.code.slice(1).slice(0, -1);

    $code.filter(`[${sel}!="${lang}"]`).removeClass("js-active");
    $code.filter(`[${sel}="${lang}"]`).addClass("js-active");
  }
}

window.LangSwitch = LangSwitch;

LangSwitch.initClass();
LangSwitch.init();
