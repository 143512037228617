import $ from "jquery";

import Selectize from "@selectize/selectize";

$(function() {
  const escape_html = (str: string) => `${str} `
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;"
    );

  interface SelectizeOptions {
    label: string
    title: string
    append: boolean
    className: string
  }

  // Define a plugin that handles removing an option from the list.
  Selectize.define("dms_remove_button", function(options: SelectizeOptions) {
    if (this.settings.mode === "single") { return; }

    options = $.extend({
      label: "&times;",
      title: "Remove",
      append: true,
      className: "remove",
    }, options);

    const html = `<button class='${options.className}' title='${escape_html(options.title)}' type='button' tabindex='-1'>${options.label}</button>`;

    const append = function(container: string, element: string) {
      const pos = container.search(/>\s*/) + 1;
      return container.substring(0, pos) + element + container.substring(pos);
    };

    this.setup = ((...args) => {
      const original = this.setup;

      return function() {
        if (options.append) {
          const render_item = this.settings.render.item;

          this.settings.render.item = function(...args: unknown[]) {
            return append(render_item.apply(this, args), html);
          };
        }

        original.apply(this, args);

        return this.$control.on("click", `.${options.className}`, (e: InputEvent) => {
          e.preventDefault();
          if (this.isLocked) { return; }

          const $item = $(e.currentTarget).parent();
          this.setActiveItem($item);
          if (this.deleteSelection()) {
            return this.setCaret(this.items.length);
          }
        });
      };
    })();
  });

  // Disable the submit button when submitting a form
  $("form").on("submit", function() {
    $(this).find("input[type=submit]").prop("disabled", true);
  });

  const REGEX_EMAIL = new RegExp("^[^@\\s]+@([^@\\s]+\\.)+[^@\\W]+$");
  const KEY_RETURN  = 13;

  const selectize_options = function(type: string) {
    return {
      persist: false,
      maxItems: null,
      maxOptions: 3,
      plugins: ["dms_remove_button"],
      valueField: "value",
      searchField: "value",
      selectOnTab: true,
      disableDelete: true,
      render: {
        item(item, escape) {
          return `<div class='${type}-item'><span>${escape(item.value)}</span></div>`;
        },
        option(item, escape) {
          return `<div class='${type}-item'><span>${escape(item.value)}</span></div>`;
        }
      },
      closeAfterSelect: true,
      createOnBlur: true,
      createFilter: type === "email" ?  REGEX_EMAIL : undefined,
      create: true,
      onInitialize() {
        const values = this.$input.parents("form").data(`account-${type}s`);

        if(values) {
          values.split(",").map((value) => this.addOption({value}));
        }
      }
    };
  };

  // Selectize is used for Snitch tag fields and email autocompletion.
  $(".tag-autocomplete").selectize(selectize_options("tag"));
  $(".email-autocomplete").selectize(selectize_options("email"));

  // The Integration tag modal is dynamic and needs selectize to be enabled
  // once it is loaded.
  $(document).on("modal:open", () => $("#integration_tags").selectize(selectize_options("tag")));

  // Pressing ENTER / RETURN in a Selectize box should complete the entry and
  // not submit the form.
  $(document).on("keydown", ".selectize-input input", function(e) {
    if (e.keyCode === KEY_RETURN) {
      e.preventDefault();
    }
  });
});
