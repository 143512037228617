// Handlers for the projects dropdown menu
(function() {
  const menu = document.getElementById("projects-dropdown");
  if(!menu) {
    return;
  }

  // Clicking on the project name in the upper left will show the list of
  // projects.
  document.querySelector("[data-behavior='dropdown:projects']").addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();

    menu.classList.toggle("hidden");
  });

  // Clicking on the menu itself will not close the menu
  menu.addEventListener("click", (e) => e.stopPropagation());

  // Clicking anywhere on the document will hide the projects menu.
  document.querySelector("body").addEventListener("click", () => menu.classList.add("hidden"));
})();

// Handlers for the user dropdown menu
(function() {
  const menu = document.getElementById("user-dropdown");
  if(!menu) {
    return;
  }

  // Clicking the customer's name in the upper right will show the list of
  // account level options.
  document.querySelector(".topbar-user__name").addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();

    menu.classList.toggle("hidden");
  });

  // Clicking on the menu itself will not close the menu
  menu.addEventListener("click", (e) => e.stopPropagation());

  // Clicking anywhere on the document will hide the projects menu.
  document.querySelector("body").addEventListener("click", () => menu.classList.add("hidden"));
})();
