import $ from "jquery";

$(function() {
  // As part of information gathering, try to determine if the user has
  // JavaScript enabled by adding a new form element.
  //
  // Placed about the page check because this is used on the Trial page as
  // well.
  $("form#signup-form").append("<input type='hidden' name='js' value='2'>");

  // This form only applies to the user profile form.
  if (!document.querySelector("body.registrations")) {
    return;
  }

  const editForm = $("form.account-basic-info-form");
  const modal    = $("div.confirm-password-modal");
  const submit   = editForm.find("input[type=submit]");
  const cancel   = editForm.find("button[type=reset]");

  // Append a hidden field for the current password since the one in the form
  // is hidden by a noscript tag.
  const current = $("<input type='hidden' name='user[current_password]' />");
  editForm.append(current);

  // Disable the submit button until changes are made
  editForm.on("reset", function() {
    submit.prop("disabled", "true");
    cancel.hide();
  });
  editForm.trigger("reset");

  editForm.find(":input").on("keyup change", function() {
    submit.prop("disabled", false);
    cancel.show();
  });

  submit.on("click", function() {
    modal.on($.modal.BEFORE_OPEN, function() {
      // Ensure the confirmation field is empty
      $(this).find("form").each(function() {
        this.reset();
        return true;
      });
    });

    // Focus the confirmation input field
    modal.on($.modal.OPEN, function() {
      const input = this.querySelector("input");
      if(input) {
        input.focus();
      }
    });

    modal.modal({
      showClose: false
    });

    return false;
  });

  modal.find("form").on("submit", function(e) {
    current.val($(e.target).find("input[name=confirm-password]").val());
    editForm.trigger("submit");

    return false;
  });
});
