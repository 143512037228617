import $ from "jquery";

// /cases/:case_id/team user interface
if(document.querySelector("body.cases.users")) {
  const userRoleChanged = function(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    const form = target.closest("form");

    // Changing anyone else's role does not require confirmation.
    if(target.dataset.confirm !== "true") {
      form.submit();
      return
    }

    // Using data-confirm in the views to specify that we need a confirmation
    // modal.
    const modal = $(document.querySelector("div.confirm-role-modal").cloneNode(true));

    // Closing the modal will reset the selection
    modal.on("modal:close", function() {
      form.reset();
      modal.remove();
    });

    // Clicking submit will confirm the change
    modal.on("click", "input[type=submit]", function(e) {
      e.preventDefault();
      form.submit();
    });

    // Show the modal
    modal.modal();
  }

  // Handle changes to user access role. Changing a user to either admin or
  // member will submit the form automatically. Changing yourself from an admin
  // to a member will ask for confirmation.
  document.querySelectorAll("select[name='admin']").forEach(
    (el: HTMLInputElement) => el.addEventListener("change", userRoleChanged)
  );
}
