(function() {
  const swap_checkin_data = function(): void {
    const data = this.getAttribute("data-value")
    const oldData = this.innerHTML

    this.textContent = data
    this.setAttribute("data-value", oldData)
  }

  document.querySelectorAll("text.checked_in_at").forEach(
    function(el: HTMLElement): void {
      el.addEventListener("mouseenter", swap_checkin_data)
      el.addEventListener("mouseout", swap_checkin_data)
    }
  )
})();
